import antd from 'ant-design-vue/es/locale-provider/zh_CN'
import momentCN from 'moment/locale/zh-cn'
import menu from './zh-CN/menu'

const components = {
  antLocale: antd,
  momentName: 'zh-cn',
  momentLocale: momentCN
}

export default {
  message: '-',

  'layouts.usermenu.dialog.title': '信息',
  'layouts.usermenu.dialog.content': '您确定要退出登录吗？',
  'user.login.title': '登录',
  'user.login.emailPlaceholder': '邮箱',
  'user.login.passwordPlaceholder': '请输入密码',
  'user.login.passwordRemember': '记住密码',
  'user.login.passwordForge': '忘记密码？',
  'user.login.success': '确定',
  'user.forgotPassword.title': '忘记密码',
  'user.forgotPassword.resetSuccess': '重置密码',
  'user.register.title': '注册',
  'user.register.emailPlaceholder': '邮箱',
  'user.register.emailEffective': '请输入有效邮箱地址',
  'user.register.emailCode': '验证码',
  'user.register.getEmailCode': '获取验证码',
  'user.register.passwordPlaceholder': '8-20位密码，必须包含数字和字母',
  'user.register.tips': '暂时仅支持主讲教师注册账号',
  'user.material.title': '完善资料',
  'user.material.uploadBtn': '上传本地头像',
  'user.material.tips': ' 请上传本人清晰人像照片，标准正方形，使用正式职业照',
  'user.material.success': '完成',
  ...components,
  ...menu
}
